import AWS from "aws-sdk"
import "amazon-connect-streams";
import "amazon-connect-chatjs";
import "amazon-connect-taskjs";
import React, { memo, useRef, useEffect, useContext, useCallback, useState } from "react";
import { AgentContext } from './AgentContextProvider'
import { genLogger } from "../lib.js";
import subscribeToAgentEvents from './agentEvents.js';
import { loginUrl, ccpUrl, api_url, apireq } from '../config.js';
import { apiCCPConfig } from '../services/models/ccpconfig.js';
import Alert from "./assets/alert.mp3"
import Icon from "./assets/chc-icon.png"

const connect = window.connect;
const agent = connect.agent;

// Default softphone manager parameters with allowFramedSoftphone flag and VDI platform
let softphoneManagerParams = {
  allowFramedSoftphone: true,
  VDIPlatform: "CITRIX"
}


const name = "CustomCCP";
const { log, error } = genLogger(name);

export let idToken
const CustomCCP = (props) => {
  const ref = useRef();
  const state = useContext(AgentContext)
  const isLoggedIn = state.isLoggedIn
  let token = useRef(null)
  idToken = state.idToken
  let groups = props.groups;

  const styles = {
    ccp:{
      height: groups.includes('Salesforce') ? 0 : "500px",
      width: groups.includes('Salesforce') ? 0 : "98%",
    }
  };
  useEffect(()=>{
    token.current = props.idToken
    checkVdiClientStatus();

  },[props.idToken])

  const invokeMethod = (val, username, id,  queues, channels, attributes, widgets, routingProfile, idToken) => {

    state.setIsLoggedIn(val, username, id, queues, channels, attributes, widgets, routingProfile, idToken)
  }

    function notifyMe() {
        if (Notification.permission !== 'granted')
            Notification.requestPermission();
        else {
            var notification = new Notification('Amazon Connect Chat', {
                icon: Icon,
                body: 'You have a new chat message',
            });
            notification.onclick = function () {
                window.focus();
            };
        }
    }

  function getNumberOfDays() {
    let contacthistory = (JSON.parse(localStorage.getItem('ContactHistory')) ? JSON.parse(localStorage.getItem('ContactHistory')) : [])

    //If contact history is not an empty array, remove any contacts that are oldler than today
    if (contacthistory != []) {
      let today = new Date();
      let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      for (var i = contacthistory.length - 1; i >= 0; i--) {
        if (date != contacthistory[i]['date']) {
          contacthistory.splice(i, 1);
        }
      }
      localStorage.setItem("ContactHistory", JSON.stringify(contacthistory));

    } else {
      //If contact is an empty array do nothing
      return
    }
  }


  async function getCcpConfig(routingProfile) {

    try {
         let response = await apiCCPConfig
          .put({
            routingProfile: routingProfile
          })

          const data = await response;
          return data;

    } catch (e) {
      console.log(e)
      return e
    }
  }

  async function getAgentId(agentQueue){
    try{
    const AGENT_QUEUE_PATTERN = new RegExp('arn:aws:connect:([\\w|\\-]+):(\\w+):instance/([\\w|\\-]+)/queue/agent/([\\w|\\-]+)');
        // group[0] all
        // group[1] region
        // group[2] AWS AccountID
        // group[3] Amazon Connect InstanceID
        // group[4] AgentID
        console.log(agentQueue)
        const groups = AGENT_QUEUE_PATTERN.exec(agentQueue[0].queueARN);
        const id = `${groups[4]}`;
        console.log(id)
        return id
    }catch(e){console.log(e)}
        }


  useEffect(() => {

    try {
      log("init start");
      if (typeof window == "undefined") throw new Error("Window missing");
      if (typeof window.connect === "undefined")
        throw new Error("Global Connect parameter missing");

      checkVdiClientStatus();

    } catch (e) {
      error(e);
    }
  },[]);

  
// Check endpoint connection status
function checkVdiClientStatus() {
  console.log("***********Checking VDI Status*************");
  // Default VDI connection status message to connecting
  var statusMessage = "VDI client connecting ...";        
  // Set allowFallback to enable fallback to non-redirected audio    
  let allowFallback = true;
  // isTimerExpired is used to flag if we have received a callback from UCSDK
  let isTimerExpired = false;
  // Set a timer to check if we have received a callback from UCSDK
  let timer = setTimeout(() => {
      console.log("index - checkVdiClientStatus - Timeout");
      // Set isTimerExpired to true to prevent further processing
      isTimerExpired = true;
      // Update VDI connection status message to Disconnected            
      statusMessage = "VDI client disconnected. Disconnect reason: Failed to get a response from Citrix UC SDK";
      // Check to enable fallback to non-redirected audio
      if(allowFallback) {            
          // Initialize softphone manager with allowFramedSoftphone flag only by removing VDIPlatform
          delete softphoneManagerParams.VDIPlatform;
          // Append status message with fallback to non-redirected audio enabled
          statusMessage = statusMessage + " - Fallback to non-redirected audio enabled";
      }   
      console.log(statusMessage);
      // Initialize CCP
      initializeCCP(softphoneManagerParams);         
  }, 5000)

  // Create a new Citrix strategy to load the UCSDK
  new connect.CitrixVDIStrategy();
  // Check if window.CitrixWebRTC and its setVMEventCallback method exist before calling it
  if (window.CitrixWebRTC && typeof window.CitrixWebRTC.setVMEventCallback === 'function') {
      // Check if window.CitrixWebRTC and its setVMEventCallback method exist before calling it
      window.CitrixWebRTC.setVMEventCallback((event) => {
          console.log("index - checkVdiClientStatus - setVMeventCallback: " + JSON.stringify(event));        
          // Check if eventType is vdiConnected or vdiDisconnected to prevent further processing
          if (event['event'] === 'vdiClientConnected' || event['event'] === 'vdiClientDisconnected') {                        
              // Check if eventType is vdiConnected and isTimerExpired is false to prevent further processing
              if (event['event'] === 'vdiClientConnected' && !isTimerExpired) {                                                             
                  console.log("index - checkVdiClientStatus - vdiClientConnected");            
                  // If VDI client is connected, stop the timer
                  clearTimeout(timer);            
                  // Applications should register any Webrtc audio elements with SDK in order to properly support redirected audio play.
                  var remoteAudioElement = document.getElementById('remote-audio');
                  window.CitrixWebRTC.mapAudioElement(remoteAudioElement);
                  // Update VDI connection status message to Connected            
                  statusMessage = "VDI client connected."
                  console.log(statusMessage);
                  // Set isTimerExpired to true to prevent further processing
                  isTimerExpired = true;
              // Check if eventType is vdiDisconnected and isTimerExpired is false to prevent further processing
              } else if (event['event'] === 'vdiClientDisconnected' && !isTimerExpired) {
                  console.log("index - checkVdiClientStatus - vdiClientDisconnected");
                  // If VDI client is disconnected, stop the timer
                  clearTimeout(timer);
                  // Update VDI connection status message to Disconnected            
                  statusMessage = "VDI client disconnected. Disconnect reason: " + event['reason'];
                  // Check to enable fallback to non-redirected audio
                  if(allowFallback) {
                      // Initialize softphone manager with allowFramedSoftphone flag only by removing VDIPlatform
                      delete softphoneManagerParams.VDIPlatform;
                      // Append status message with fallback to non-redirected audio enabled
                      statusMessage = statusMessage + " - Fallback to non-redirected audio enabled";
                  }   
                  console.log(statusMessage);
                  // Set isTimerExpired to true to prevent further processing
                  isTimerExpired = true;
              }                
              // Initialize CCP
              initializeCCP(softphoneManagerParams);                
          }
          else {
              // If eventType is not vdiConnected or vdiDisconnected, ignore the event
              console.log("index - vmEventCallback - Event not type Connected or Disconnected - Ignoring");
          }        
      });
  } else {
      // If window.CitrixWebRTC is not available
      console.log('index - checkVdiClientStatus - window.CitrixWebRTC.setVMEventCallback is not available.');
  } 
}

function initializeCCP(softphoneManagerParams){
  connect.core.initCCP(ref.current, {
    ccpUrl: ccpUrl,
    loginPopup: true,
    loginPopupAutoClose: true,
    loginUrl: loginUrl,
    region: "us-east-1",
    pageOptions: {
      enableAudioDeviceSettings: true,
      enablePhoneTypeSettings: true,
    },
    softphone: { allowFramedSoftphone: false },
  }

    );

    connect.core.initSoftphoneManager(softphoneManagerParams);
    connect.contact(function (contact) {
        const c = contact;
        if (contact.getType() !== connect.ContactType.CHAT) {
            return;
        }
        c.onConnecting(function (c) {
            console.log("incoming");
        });
        c.onAccepted(async () => {
            if (contact.getAgentConnection().getMediaType() === connect.MediaType.CHAT) {
                contact.getAgentConnection().getMediaController()
                    .then(controller => {
                        controller.onMessage(function (response, contentType) {
                            if (response.data.ParticipantRole === 'CUSTOMER') {
                                console.log(response.data)
                                notifyMe()

                            }
                        })
                    })
            }
        })
    });

  connect.agent(subscribeToAgentEvents);
  var eventBus = connect.core.getEventBus();
  eventBus.subscribe(connect.AgentEvents.INIT, async function (agent) {

    try {
      const agentQueue = agent.getConfiguration().routingProfile.queues.filter(queue => queue.name === null)
      const username = agent.getConfiguration().username;
      const agentId = await getAgentId(agentQueue)
      let routingProfile = agent.getRoutingProfile();
      let queues = routingProfile.queues.filter(items => items.name !== null);
      let rawchannels = agent.getChannelConcurrency();
      let channels = Object.entries(rawchannels).filter(([key, value]) => value > 0).map(([k]) => k);
      let ccpConfig = await getCcpConfig(routingProfile.name);
      let widgets = await ccpConfig.ccpWidgets;
      window.attributes = await ccpConfig.ccpAttributes;
      getNumberOfDays();
      invokeMethod(true, username, agentId, queues, channels, window.attributes, widgets, routingProfile.name, token.current);
    } catch (e) { console.log(e) }
  });
}

  return (
    <>

         <div ref={ref} style={styles.ccp}>
         <audio id="remote-audio" autoplay></audio>
      </div>

    </>
  );

};

export default memo(CustomCCP);
